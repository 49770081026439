import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export const SelectField = ({
  name,
  fieldId,
  onChange,
  placeholder,
  errorMessage,
  valid = true,
  disabled,
  options,
  valueField,
  size = "small",
  displayField,
  value,
  required,
  fullWidth = true,
  variant,
  sx,
}: ISelect) => {
  return (
    <TextField
      fullWidth={fullWidth}
      select
      required={required}
      id={fieldId}
      value={value}
      label={placeholder}
      variant={variant}
      onChange={onChange}
      name={name}
      disabled={disabled}
      size={size}
      error={!valid}
      helperText={!valid && errorMessage}
      sx={sx}
    >
      <MenuItem disabled value="">
        <em>{placeholder}</em>
      </MenuItem>
      {options.map((option: any) => (
        <MenuItem value={option[valueField]} key={option[valueField]}>
          {option[displayField]}
        </MenuItem>
      ))}
    </TextField>
  );
};

export interface ISelect {
  name: string;
  fieldId: string;
  onChange: any;
  errorMessage?: string;
  valid?: any;
  options: any[];
  disabled?: boolean;
  displayField: string;
  valueField: string;
  size?: "small" | "medium";
  placeholder?: string;
  value?: any;
  margin?: "dense" | "none" | "normal";
  variant?: "outlined" | "filled" | "standard";
  required?: boolean;
  shrink?: boolean;
  inputLabelSx?: any;
  fullWidth?: boolean;
  sx?: any;
}
